@use 'src/styles/variables' as var;
@use 'src/styles/mixins' as mix;

.wrapper {
  width: 100%;

  @include mix.vp-1024 {
    border-radius: 0;
    padding: 0 16px;
  }
}

.container {
  width: 100%;
  max-width: 600px;
  margin: 0 auto;
  display: flex;
  flex-direction: column;
  gap: 24px;
}

.box {
  padding: 32px 20px;
  position: relative;
  z-index: 0;
  max-width: 1024px;
  margin: 0 auto;
  @include mix.block;
  overflow: hidden;
}

.title {
  @include mix.text(32, 700, 32);
  text-align: center;
}

.description {
  @include mix.text(24, 400, 24);
  text-align: center;

  @include mix.vp-1024 {
    @include mix.text(20, 400, 20);
  }
}

.button {
  max-width: 300px;
  margin: 0 auto;
}
